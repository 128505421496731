"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Template_1 = require("../components/Template");
class Main extends React.Component {
    render() {
        return (React.createElement(Template_1.Template, null,
            React.createElement("div", { className: 'main' },
                React.createElement("div", { className: 'name' }, "YOUSUF NEJATI"),
                React.createElement("div", { className: 'profession' }, "- JavaScript Engineer -")),
            React.createElement("a", { target: "_blank", href: "https://github.com/ynejati" },
                React.createElement("img", { src: "dist/GitHub-Mark-Light-32px.png" }))));
    }
}
exports.Main = Main;
