"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ReactDOM = require("react-dom");
const Main_1 = require("./pages/Main");
require("./css/style.css");
class App extends React.Component {
    render() {
        return (React.createElement("div", { className: 'app' },
            React.createElement(Main_1.Main, null)));
    }
}
;
ReactDOM.render(React.createElement(App, null), document.getElementById('root'));
