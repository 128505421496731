"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const bind_decorator_1 = require("bind-decorator");
const Header_1 = require("./Header");
const Footer_1 = require("./Footer");
class Template extends React.Component {
    setMainRef(ref) {
        this.mainNode = ref;
    }
    render() {
        return (React.createElement("div", { className: `template dark` },
            React.createElement("div", { className: 'header-container' },
                React.createElement(Header_1.Header, null)),
            React.createElement("div", { className: 'main-container', ref: this.setMainRef }, this.props.children),
            React.createElement("div", { className: 'footer-container' },
                React.createElement(Footer_1.Footer, null))));
    }
}
__decorate([
    bind_decorator_1.default
], Template.prototype, "setMainRef", null);
exports.Template = Template;
